@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: "Itim";
    src: local("Itim-Regular"),
        url("../fonts/Itim-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "GillSansMT";
    src: local("Gill-Sans-MT"),
        url("../fonts/Gill-Sans-MT.ttf") format("truetype");
}
@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
        url("../fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"), url("../fonts/Montserrat.ttf") format("truetype");
}

.upgradeToPro {
    background: linear-gradient(278deg, #00ffa3 0%, #0128ff 102.13%);
}

.lidabro-gradient {
    background: linear-gradient(278deg, #00ffa3 0%, #0128ff 102.13%);
}
.lidabro-shop-gradient {
    background: linear-gradient(26deg, #f8fbff 2.75%, #fdfdff 81.02%);
}
.lidabro-text-gradient {
    background: linear-gradient(46deg, #00ffa3 -66.24%, #0128ff 385.48%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.lidabro-gradient-eyezone {
    background: var(
        --Lidabro-bluere,
        linear-gradient(278deg, #00ffa3 0%, #0128ff 102.13%)
    );
}
.lidabro-button-shadow {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.container-center {
    margin-left: auto;
    margin-right: auto;
}

.bg-header {
    background: linear-gradient(
        180deg,
        #f8f8ff 50%,
        #f8f8ff 50%,
        rgba(248, 248, 255, 0) 100%,
        rgba(248, 248, 255, 0) 100%
    );
    width: 100vw;
}

.bg-page {
    background: linear-gradient(179deg, #eef1ff 0.46%, #fff 99.65%);
}

.bg-mobile-menu {
    background: linear-gradient(
        193deg,
        rgba(255, 255, 255, 0.5) 7.46%,
        rgba(234, 239, 255, 0.75) 100%
    );
    backdrop-filter: blur(5px);
}

.bg-mobile-analytic {
    background: linear-gradient(
        161deg,
        #fff -3.91%,
        rgba(255, 255, 255, 0) 72.11%
    );
}

.bg-mobile-analytic2 {
    background: linear-gradient(
        191deg,
        rgba(255, 255, 255, 0) 7.95%,
        rgba(0, 71, 255, 0.41) 95.82%
    );
}

.bg-mobile-menu-header {
    background: linear-gradient(
        180deg,
        #f8f8ff 0%,
        rgba(248, 248, 255, 0) 100%
    );
}

.lidabro-text {
    background: linear-gradient(277deg, #00ffa3 0%, #0128ff 37.9%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.lidabro-text2 {
    background: linear-gradient(277deg, #00ffa3 76.59%, #0128ff 101.89%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.lidabro-text3 {
    background: linear-gradient(275deg, #00ffa3 57.95%, #0128ff 110.18%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.lidabro-text4 {
    background: linear-gradient(278deg, #00ffa3 48.45%, #0128ff 100.53%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.lidabro-overlay::before {
    background: linear-gradient(
        27deg,
        rgba(48, 106, 255, 0.7) 15.2%,
        rgba(48, 106, 255, 0) 71.25%
    );
}

.lidabro-blue-circle {
    border-radius: 593px;
    background: #306aff;
    filter: blur(200px);
}

.lidabro-green-circle {
    border-radius: 593px;
    background: #00ffa3;
    filter: blur(200px);
}

.lidabro-blue-circle-mobile {
    border-radius: 593px;
    background: #306aff;
    filter: blur(100px);
}

.lidabro-green-circle-mobile {
    border-radius: 593px;
    background: #00ffa3;
    filter: blur(100px);
}

.bg-iPad {
    background-image: url("../../public/iPad.png");
    flex-shrink: 0;
    --b: 30px;
    --x: -300px;
    --y: 400px;
    --s: 0px;
}

.bg-iPad-test:before {
    content: "";
    position: absolute;
    inset: calc(-1 * var(--s));
    transform: translate3d(var(--x), var(--y), -1px);
    clip-path: polygon(
        -100vmax -100vmax,
        100vmax -100vmax,
        100vmax 100vmax,
        -100vmax 100vmax,
        -100vmax -100vmax,
        calc(0px + var(--s) - var(--x)) calc(0px + var(--s) - var(--y)),
        calc(0px + var(--s) - var(--x)) calc(100% - var(--s) - var(--y)),
        calc(100% - var(--s) - var(--x)) calc(100% - var(--s) - var(--y)),
        calc(100% - var(--s) - var(--x)) calc(0px + var(--s) - var(--y)),
        calc(0px + var(--s) - var(--x)) calc(0px + var(--s) - var(--y))
    );
    filter: blur(var(--b));
    background: linear-gradient(
        245deg,
        rgba(17, 11, 95, 0.8) 9.81%,
        rgba(0, 0, 0, 0) 73.08%
    );
}

.lidabro-border {
    border: 4px solid rgba(187, 201, 255, 0.56);
    border-radius: 27px;
}

.category-shadow {
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
}

#root {
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

dialog::backdrop {
    background-color: rgba(0, 0, 0, 0.25);
}
